import { useFormContext } from "react-hook-form";
import Ternary from "src/components/ternary";
import { AUTH_INFO, USE_LOGIN } from "src/config";
import AuthBanner from "./auth-banner";

const AuthHelper = () => {
  const { setValue } = useFormContext();
  const { admin, user } = AUTH_INFO;

  const selectCredentials = (info) => () => {
    setValue("email", info.email);
    setValue("password", info.password);
  };

  return (
    <Ternary
      when={USE_LOGIN}
      then={
        <>
          <AuthBanner
            name="Admin"
            info={admin}
            onClick={selectCredentials(admin)}
          />
          <AuthBanner
            name="User"
            info={user}
            onClick={selectCredentials(user)}
          />
        </>
      }
    />
  );
};

export default AuthHelper;
