import React from "react";
import { AuthWraper } from "./ResetPassword";

const Leadcapture = () => {
  return (
    <div>
      <AuthWraper> LeadCapture</AuthWraper>
    </div>
  );
};

export default Leadcapture;
