import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Lead = Loadable(lazy(() => import("src/pages/lead/admin/list")));

const lead = [
  {
    path: "lead",
    children: [{ index: true, element: <Lead /> }],
  },
];

export default lead;
