import axiosInstance from "src/utils/axios";

const addVisitor = async (data) => {
  const reqData = new FormData();
  Object.entries(data).forEach(([k, v]) => reqData.append(k, v));
  try {
    const { data, status } = await axiosInstance.post("api/visitors", reqData);
    if (status === 200) return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export default addVisitor;
