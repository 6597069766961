import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Translate from "src/components/translate";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { PATH_AUTH } from "src/routes/paths";

const NoAccountSection = () => {
  const smUp = useResponsive("up", "sm");

  return (
    !smUp && (
      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        <LanguagePopover />
        <Translate>register.don't_have</Translate> &nbsp;?&nbsp;
        <Link
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          <Translate>register.get_started</Translate>
        </Link>
      </Typography>
    )
  );
};

export default NoAccountSection;
